import React, { useState, useRef, useEffect, useContext } from 'react';
import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom';

import { Button, Modal } from 'antd';

import { useTranslation } from 'react-i18next';
import i18n from '../../i18next'

import { GlobalOutlined, SoundOutlined, MutedOutlined, InfoCircleOutlined, PictureOutlined, DownCircleOutlined, UpCircleOutlined, ZoomInOutlined, ZoomOutOutlined, HomeOutlined, CloseOutlined, ExpandOutlined, CompressOutlined, UndoOutlined } from '@ant-design/icons';

import '../Footer/styles.css'

import { AutoRotateContext } from '../pauseState/AutoRotateContext';
import { events } from '@react-three/fiber';

function GGMap() {
    const [isMapVisible, setIsMapVisible] = useState(true);
    const [size, setSize] = useState('large');

    const handleToggleMap = () => {
        setIsMapVisible(!isMapVisible);
    };

    return (
        <div className='ggMap'>
            {isMapVisible && (
                <div className='container' style={{ zIndex: 99999999 }}>
                    <Button
                        onClick={() => setIsMapVisible(false)}
                        type="primary"
                        icon={<CloseOutlined />}
                        size={size}
                        className="close-button"
                        color='#fa9627'
                    />
                    <iframe
                        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d15018.734198011669!2d105.7579011871582!3d19.768595100000006!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3136f87eb8b4c8c5%3A0x88d1e9c521aef3a9!2zxJBhzKNpIEhvzKNjIEjDtMyAbmcgxJDGsMyBYyAx!5e0!3m2!1sfr!2s!4v1715850514238!5m2!1sfr!2s"
                        className="map-iframe"
                        allowFullScreen=""
                        loading="lazy"
                        referrerPolicy="no-referrer-when-downgrade"
                    ></iframe>
                </div>
            )}
        </div>
    );
}

function ImageGrid({ onClose }) {
    const images = [
        'https://cdn.tuoitre.vn/zoom/700_525/2022/11/28/mot-goc-truong-dh-hong-duc-16696094981071238083194-crop-1669610063005479889994.jpg',
        'https://lienhehotro.vn/wp-content/uploads/2021/09/truong-dai-hoc-hong-duc.jpg',
        'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTrttiH9OhWXGQODBkMvOFWYGeBk5ta2MWsiA&s',
        'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSRyHvTU3K7rUSamQnSFj1TkBPwU6-DrCw6xA&s',
        'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS-VfB1apjnhknZr7jbQ0ezAp9kO_nYgrJF3Q&s',
        'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRS7x6SnxSDfIGLmArF7wFGWZhnsFzkFJY2yw&s',
        'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT6pcGIjIaGqigMhKPsGRAejYPyt1pt0orRvA&s',
        'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTaeVgDdobSuHqW0GLigpQP_dVd47QvmUTGUw&s',
        'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT4-9R8e3hLilFbw8O2r_cwwE9v-TLIAuSsNQ&s',
        'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSj7zfQ1KNu0BxWtthrIylLQl76o_uN5_dqpQ&s',
        'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ_5s96uoEv2iNmcc826OTKwMYf5BG_5tNEnPPAZ6jPsWrBPXNEt84yuFyOBGpw1CDIVl8&usqp=CAU',
        'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTPa0vSkirio1EQpSC60dRno8eqjLLJcLT1sS_fFZOC-z_ha2dP8tbtK0vo98G9PM5yYqQ&usqp=CAU',
    ];

    const containerRef = useRef(null);

    useEffect(() => {
        function handleClickOutside(event) {
            if (containerRef.current && !containerRef.current.contains(event.target)) {
                onClose();
            }
        }
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [onClose]);

    return (
        <div className='ImageContainer' ref={containerRef}>
            <div style={{ height: 40,  alignContent: 'center',   }}>
                <Button
                    className='closeButton'
                    onClick={onClose} type="primary"
                    borderRadius = {1}
                    icon={<CloseOutlined color='black'/>}
                />
            </div>
            <div className='gridImage'>
                {images.map((src, index) => (
                    <div className='imgContainer' key={index}>
                        <img className='img' src={src} alt={`img-${index}`} />
                    </div>
                ))}
            </div>
        </div>
    );
}

function BuildingInfoModal() {
    const [isModalVisible, setIsModalVisible] = useState(true);
    const [exitAnimation, setExitAnimation] = useState(false);

    const location = useLocation();
    const containerRef = useRef(null);

    useEffect(() => {
        if (!isModalVisible) {
            setExitAnimation(true);
            const timer = setTimeout(() => {
                setExitAnimation(false);
            }, 100); // Match the duration of the slide-out animation

            return () => clearTimeout(timer);
        }
    }, [isModalVisible]);

    useEffect(() => {
        function handleClickOutside(event) {
            if (containerRef.current && !containerRef.current.contains(event.target)) {
                handleClose();
            }
        }

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    const handleClose = () => {
        setExitAnimation(true);
    };

    const { t, i18n } = useTranslation();

    return (
        <div
            className={`custom-modal ${isModalVisible ? 'custom-modal-enter' : ''} ${exitAnimation ? 'custom-modal-exit' : ''}`}
            ref={containerRef}
        >
            <Button onClick={handleClose} type="primary" icon={<CloseOutlined style={{ color: '#fa9627', width: 11, height: 11, }} />} size='2' style={{ position: 'absolute', top: 10, right: 10, cursor: 'pointer', zIndex: 10001, backgroundColor: '#ffffff', borderRadius: '50%', width: 25, height: 25, }} />
            {location.pathname === '/' && (
                <>
                    <h3>{t('nameSchool')}</h3>
                    <div className="scrollable-content">
                        <p>{t('aboutSchool')}</p>
                    </div>
                </>
            )}
            {location.pathname === '/a3' && (
                <>
                    <h3>{t('a3')}</h3>
                    <div className="scrollable-content">
                        <p>{t('aboutA3')}</p>
                    </div>
                </>
            )}
            {location.pathname === '/library' && (
                <>
                    <h3>{t('Library')}</h3>
                    <div className="scrollable-content">
                        <p>{t('aboutLibrary')}</p>
                    </div>
                </>
            )}
        </div>
    );
}

function Footer({ handleZoomIn, handleZoomOut }) {
    // show, hide Map
    const [showMap, setShowMap] = useState(false);
    const handleShowMap = () => {
        setShowMap(!showMap);
    };

    // show , hide Footer
    const [isFooterVisible, setIsFooterVisible] = useState(true);
    const toggleFooterVisibility = () => {
        setIsFooterVisible(!isFooterVisible);
    };

    // Play , Pause AUDIO
    const [isPlaying, setIsPlaying] = useState(false);

    const handleToggleAudio = () => {
        if (isPlaying) {
            audioRef.current.pause();
        } else {
            audioRef.current.play();
        }
        setIsPlaying(!isPlaying);
    };

    // show, hide Image
    const [showImageGrid, setShowImageGrid] = useState(false);

    const handleToggleImageGrid = () => {
        setShowImageGrid(!showImageGrid);
    };

    // show, hide About
    const [showAbout, setShowAbout] = useState(false);
    const handleShowAbout = () => {
        setShowAbout(!showAbout)
    };
    const audioRef = useRef(null);

    // FullScreen 
    const [isFullScreen, setIsFullScreen] = useState(false);

    const toggleFullScreen = () => {
        if (!isFullScreen) {
            if (document.documentElement.requestFullscreen) {
                document.documentElement.requestFullscreen();
            } else if (document.documentElement.mozRequestFullScreen) { // Firefox
                document.documentElement.mozRequestFullScreen();
            } else if (document.documentElement.webkitRequestFullscreen) { // Chrome, Safari and Opera
                document.documentElement.webkitRequestFullscreen();
            } else if (document.documentElement.msRequestFullscreen) { // IE/Edge
                document.documentElement.msRequestFullscreen();
            }
            setIsFullScreen(true);
        } else {
            if (document.exitFullscreen) {
                document.exitFullscreen();
            } else if (document.mozCancelFullScreen) { // Firefox
                document.mozCancelFullScreen();
            } else if (document.webkitExitFullscreen) { // Chrome, Safari and Opera
                document.webkitExitFullscreen();
            } else if (document.msExitFullscreen) { // IE/Edge
                document.msExitFullscreen();
            }
            setIsFullScreen(false);
        }
    };

    return (
        <>
            <audio ref={audioRef} src="nhacnen.mp3" loop />
            {isFooterVisible && (
                <div style={{
                    width: '100%',
                    height: '35px',
                    backgroundColor: 'rgba(243, 113, 37, 0.9)',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-around',
                    position: 'fixed',
                    bottom: 0,
                    zIndex: 999999,
                    opacity: 0.8
                }}>
                    <div>
                        <DownCircleOutlined onClick={toggleFooterVisibility} style={{ color: 'white', fontSize: 25, paddingRight: 30, cursor: 'pointer' }} title='Ẩn thanh công cụ' />
                    </div>
                    <div style={{ display: 'flex', }}>
                        <GlobalOutlined onClick={handleShowMap} style={{ color: 'white', fontSize: 25, paddingRight: 30, cursor: 'pointer' }} title='Vị trí' />
                        {isPlaying ?
                            <SoundOutlined onClick={handleToggleAudio} style={{ color: 'white', fontSize: 25, paddingRight: 30, cursor: 'pointer' }} title='Tắt âm' />
                            : <MutedOutlined onClick={handleToggleAudio} style={{ color: 'white', fontSize: 25, paddingRight: 30, cursor: 'pointer' }} title='Tắt âm' />
                        }
                        <a href='/'><HomeOutlined style={{ color: 'white', fontSize: 25, paddingRight: 30, cursor: 'pointer' }} title='Toàn cảnh' /></a>
                        <InfoCircleOutlined onClick={handleShowAbout} style={{ color: 'white', fontSize: 25, paddingRight: 30, cursor: 'pointer' }} title='Thông tin' />
                        <PictureOutlined onClick={handleToggleImageGrid} style={{ color: 'white', fontSize: 25, paddingRight: 30, cursor: 'pointer' }} title='Tất cả các ảnh' />
                    </div>
                    <div>
                    </div>
                </div>
            )}
            {!isFooterVisible && (
                <UpCircleOutlined title='Hiện thanh công cụ' onClick={toggleFooterVisibility} style={{ color: '#fa9627', fontSize: 30, position: 'fixed', bottom: 10, right: 30, cursor: 'pointer', zIndex: 1000 }} />
            )}
            {showMap && <GGMap />}
            {showImageGrid && (
                <div style={{
                    position: 'fixed',
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                    backgroundColor: 'rgba(0, 0, 0, 0.5)',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    zIndex: 9998
                }}>
                    <ImageGrid onClose={handleToggleImageGrid} />
                </div>
            )}
            {showAbout && (
                <BuildingInfoModal />
            )}

        </>
    );
}

export default Footer;

