import React, { Suspense, useRef } from 'react';
import { Canvas } from '@react-three/fiber';
import { OrbitControls, Sky } from '@react-three/drei';
import { CubeTextureLoader } from 'three';

class Panorama extends React.Component {
    constructor(props) {
        super(props);
        this.controls = useRef();
    }

    handleClick = (position) => {
        this.controls.current.target.copy(position);
        this.controls.current.update();
    };

    render() {
        const { position } = this.props;

        return (
            <Canvas>
                <Suspense fallback={null}>
                    <Sky
                        distance={450000} // Camera distance (default=450000)
                        sunPosition={[0, 1, 0]} // Sun position [x, y, z]
                        inclination={0} // Sun elevation angle from 0 to 1 (default=0)
                        azimuth={0.25} // Sun azimuth angle from 0 to 1 (default=0.25)
                    />
                    <mesh>
                        <sphereGeometry args={[500, 60, 40]} />
                        <meshBasicMaterial attach="material" map={new CubeTextureLoader().load([
                            'Toaa3.jpg',
                            'Sanha3.jpg'
                        ])} />
                    </mesh>
                    <OrbitControls ref={this.controls} enableZoom={false} />
                </Suspense>
                <button onClick={() => this.handleClick([x1, y1, z1])} style={{ position: 'absolute', top: '10px', left: '10px' }}>
                    Go to Position 1
                </button>
                <button onClick={() => this.handleClick([x2, y2, z2])} style={{ position: 'absolute', top: '50px', left: '10px' }}>
                    Go to Position 2
                </button>
            </Canvas>
        );
    }
}

function StudyTogether() {
    return (
        <div style={{ width: '100vw', height: '100vh' }}>
            <Panorama position1={[10, 15, 35]} position2={[100, 50, 40]} /> {/* Thay [x1, y1, z1] và [x2, y2, z2] bằng các vị trí mong muốn */}
        </div>
    );
}

export default StudyTogether;
